.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: inherit;
  position: relative;

  .tabs__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;

    justify-items: flex-start;

    box-sizing: border-box;

    border-bottom: 1px solid var(--base-04);

    .tabitem {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;      

      cursor: pointer;

      padding: 0.75em 1.5em;
      margin-right: 0.5em;

      font-weight: bold;

      box-sizing: unset;
      box-sizing: border-box;

      color: var(--base-02);

      transition: color 0.2s linear;
      svg {
        padding-right: 1em;
        fill: #999;
        path {
          transition: fill 0.2s linear;
        }
      }

      &.active {
        border: 1px solid #eaeaea;
        border: none;
        border-bottom: 3px solid var(--primary);
        background-color: inherit;
        color: var(--base-dark);

        svg path {
          fill: var(--primary-accent);
        }
      }
    }
  }

  .tabs__content {
    padding: 32px 0px;

    height: 100%;
    width: 100%;

    box-sizing: border-box;

  }
}
