@import 'styles/partials/breakpoints', 'styles/partials/shadows';

@mixin responsive {
  width: 100vw;
  max-width: 100vw;

  padding: 1em !important;

  align-items: center;

  .alert {
    min-height: 60px;

    margin: 1em auto;
  }
}

.alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;

  width: 100%;

  position: relative;

  margin: 0px 2em 1em 0px;
  padding: 1em;

  background-color: white;

  border-radius: 4px;
  border: 1px solid var(--primary);

  color: var(--primary-accent);

  @include shadow-8dp();

  .alert-content {
    font-size: 14px;
  }

  .dismiss-button {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    padding-left: 2em;

    cursor: pointer;

    transition: color 200ms linear;

    &:hover {
      color: var(--primary-accent);
    }
  }

  &.error {
    color: var(--error);
    border-color: var(--error);
  }

  &.info {
    color: var(--info);
    border-color: var(--info);
  }

  &.warning {
    color: var(--warning);
    border-color: var(--warning);
  }

  &.success {
    color: var(--primary);
    border-color: var(--primary);
  }
}

.alert-container {
  z-index: 1000;

  position: fixed;
  bottom: 0px;
  right: 0px;

  max-width: 50%;

  padding-bottom: 1em;
  padding-right: 2em;

  align-items: flex-end;
  justify-content: center;

  box-sizing: border-box;

  transition: height 200ms linear;

  &.mobile {
    @include responsive();
  }

  @include respond-to(small) {
    @include responsive();
  }
}

.alert-transition-enter {
  opacity: 0;
  transform-origin: center center;
  transform: scale(0);
}
.alert-transition-enter-active {
  opacity: 1;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 200ms var(--elastic),
    opacity 200ms linear;
}
.alert-transition-exit {
  opacity: 1;
  transform-origin: center center;
  transform: scale(1);
}
.alert-transition-exit-active {
  opacity: 0;
  transform-origin: center center;
  transform: scale(0);
  transition: transform 200ms var(--elastic),
    opacity 200ms linear;
}
