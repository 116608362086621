

.divider {
  width: 100%;

  margin: 2em 0px;
  
  background-color: var(--base-04);

  &.w-title {
    margin-top: 4px;

  }
}