
.upgrade-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  z-index: 105;

  background-color: transparent;
  backdrop-filter: blur(3px);

  justify-content: space-evenly;  

  
  span {
    font-size: 1.8em;
  }
}