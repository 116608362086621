div.__react_component_tooltip.tooltip {
  background-color: var(--primary);
  color: white;

  border-radius: 4px;

  text-align: center;

  &.place-top:before,
  &.place-top:after {
    border-top-color: var(--primary) !important;
  }

  &.place-left:before,
  &.place-left:after {
    border-left-color: var(--primary) !important;
  }

  &.place-right:before,
  &.place-right:after {
    border-right-color: var(--primary) !important;
  }

  &.place-bottom:before,
  &.place-bottom:after {
    border-bottom-color: var(--primary) !important;
  }

  .no-multiline {
    white-space: nowrap;
  }

  .high-z {
    z-index: 9999;
  }

}
