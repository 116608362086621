@import 'styles/partials/shadows';

.loading-spinner {
  position: relative;
  margin: 0 auto;
  width: 150px;
  height: 150px;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  .circular {
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    
    stroke-linecap: round;
  }

  .text-content {
    position: absolute;

    width: 115px;
    height: 115px;

    top: 17px;
    left: 17px;

    border-radius: 50%;

    background-color: white;

    @include shadow-24dp();

    .indeterminate-text {
      color: #9ea3a9;

      text-transform: uppercase;

      font-weight: bold;
      font-size: 14px;

      .bounce-ball {
        width: 4px;
        height: 4px;

        border-radius: 50%;

        margin-right: 2px;

        margin-bottom: -2px;

        &:nth-child(1) {
          margin-left: 2px;
          background-color: #005a66;
          animation: bounce 1s ease-in-out infinite;
        }
        &:nth-child(2) {
          background-color: #68c3cd;
          animation: bounce 1s ease-in-out infinite;
          animation-delay: 300ms;
        }
        &:nth-child(3) {
          background-color: #ffd86f;
          animation: bounce 1s ease-in-out infinite;
          animation-delay: 600ms;
        }
      }
    }
  }

  &.indeterminate {
    .circular {
      animation: rotate 2s linear infinite;
    }

    .path {
      animation: dash 1.5s ease-in-out infinite, color 3s ease-in-out infinite;
      stroke-dasharray: 125;
    stroke-dashoffset: 0;
    }
  }

  &.controlled {
    svg {
      transform: rotate(-90deg);
    }
    path {
      transition: stroke-dashoffset 150ms linear;
    }
  }
}

.fixed-loading-container {
  position: fixed;

  width: 100%;
  height: 100%;

  top: 0px;
  left: 0px;

  background-color: #ffffff33;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 48, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-4px);
  }
  80% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0px);
  }
}
