:root {
  /* RAWS */
  // Con estos podemos hacer cosas como rgba(var(--primary-raw), 0.5)
  --base-dark-raw: 27, 41, 52;
  --base-02-raw: 88, 96, 106;
  --base-03-raw: 158, 163, 169;
  --base-04-raw: 197, 200, 203;
  --base-light-raw: 245, 246, 247;

  --primary-raw: 104, 195, 205;
  --error-raw: 255, 85, 86;
  --error-light-raw: 255, 221, 221;
  --warning-raw: 255, 180, 41;

  /* FINALS */
  --base-dark: rgb(var(--base-dark-raw));
  --base-02: rgb(var(--base-02-raw));
  --base-03: rgb(var(--base-03-raw));
  --base-04: rgb(var(--base-04-raw));
  --base-light: rgb(var(--base-light-raw));

  --primary: rgb(var(--primary-raw));

  --error: rgb(var(--error-raw));
  --error-light: rgb(var(--error-light-raw));

  --warning: rgb(var(--warning-raw));
  --warning-light: rgba(var(--warning-raw), 0.02);

  // Solo para saber que colores usan en UI Kit
  $base-dark: #1b2934;
  $base-02: #58606a;
  $base-03: #9ea3a9;
  $base-04: #c5c8cb;
  $base-light: #f5f6f7;

  $primary: #68c3cd;
  
  $warning:  #ffb429;
  $error: #ff5556;
  $error-light: #ffdddd;
}
