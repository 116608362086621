
.pagination {
  height: 50px;

  width: fit-content;

  box-sizing: border-box;

  flex-wrap: nowrap;

  font-size: 16px;

  background-color: var(--base);

  .pagination-button {
    box-sizing: border-box;

    height: 32px;
    width: 32px;

    border-radius: 50%;

    margin: 0px 0.5em;

    font-weight: 300;
    user-select: none;
    cursor: pointer;

    transition: color 100ms linear, background-color 100ms linear;

    &:last-child {
      border-right: none;
    }

    &:hover:not(.active):not(.ellipsis) {
      color: var(--primary);
    }
    
    &.active {  
      font-weight: bold;
    }

    &.ellipsis {
      cursor: default;
    }

    &.arrow-button {
      border: 1px solid rgba(197, 200, 203, 0.5);
      svg {
        width: 22px;
        height: 22px;

        path {
          fill: var(--base-dark);
        }

        &.prev-arrow {
          transform: rotate(180deg);
        }
      }

      &.prev {
        margin-right: 1em;
      }

      &.next {
        margin-left: 1em;
      }
    }
  }
}
