.link {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: var(--primary-accent);
  cursor: pointer;

  overflow: hidden;

   // 
  position: relative;

  &.inline {
    display: inline-flex;
    justify-self: self-end;
  }

  

  // &::before {
  //   will-change: width;
  //   transition: width 200ms ease-in-out;
  //   content: '';
  //   width: 50%;
  //   height: 4px;
  //   position: absolute;
  //   background-color: var(--primary);
  //   transform: translate3d(0, 0, 0);
  //   bottom: -2px;
  //   right: 0;
  // }
  // &::after {
  //   will-change: width;
  //   transition: width 200ms ease-in-out;
  //   content: '';
  //   width: 50%;
  //   height: 4px;
  //   position: absolute;
  //   background-color: var(--primary);
  //   transform: translate3d(0, 0, 0);
  //   bottom: -2px;
  //   left: 0;
  // }

  // &:hover {
  //   &::before, &::after {
  //     width: 0px;
  //   }
  // }
}
