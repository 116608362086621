@import 'styles/partials/theme';
@import 'styles/partials/ripple';
@import 'styles/partials/tooltip';
@import 'styles/partials/divider';
@import 'styles/partials/breakpoints';

@font-face {
  font-family: Averta;
  font-weight: bold;
  src: url("assets/fonts/avertabold-webfont.woff") format("woff");
}

@font-face {
  font-family: Averta;
  font-weight: 300;
  src: url("assets/fonts/avertalight-webfont.woff") format("woff");
}

@font-face {
  font-family: Averta;
  font-weight: normal;
  src: url("assets/fonts/averta-webfont.woff") format("woff");
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height: 100vh;
  margin: 0px;
  color: #333333;

  overflow-x: hidden;
  
  --elastic: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  

  @include respond-to(small) {
    min-height: calc(100vh - 70px);
    overflow-x: hidden;
  }
}

* {
  scroll-behavior: smooth;
  font-family: 'Averta', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}


::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #68c3cd;
}
