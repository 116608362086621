.multi-item-select {
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    padding: 0.5em;
    border: 1px solid var(--base-03);
    min-width: 200px;
    .item {
        text-align: center;
        cursor: pointer;
        padding: 0;
        margin: 0;
        &:hover {
            background-color: rgba(var(--primary-raw), 0.8);
            color: #FFF;
        }

        &.selected {
            background-color: var(--primary);
            color: #FFF;
        }

        &.disabled {
            background: var(--base-02);
            color: #FFF;
        }
    }
}
