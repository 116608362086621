.list-header {
  height: 60px;
  width: 100%;

  justify-content: space-between;

  padding: 1em 2em;

  color: var(--base-03);

  .list-header-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;

    height: 100%;

    align-self: stretch;
    justify-self: stretch;

    cursor: pointer;

    svg {
      margin-left: 1em;
    }

    &:hover {
      color: var(--base-02);
    }
  }

  .list-header-icon-container {
    display: flex;

    flex-basis: 10%;

    justify-self: flex-end;
    flex-wrap: nowrap;

    color: var(--base-04);

    .list-icon {
      margin-left: 1em;
    }

    svg.display-mode-icon {
      height: 30px;
      width: 30px;

      cursor: pointer;

      path,
      circle {
        fill: var(--base-04);
      }

      &.active {
        path,
        circle {
          fill: var(--base-dark);
        }
      }
    }
  }
}
