@import 'styles/partials/shadows';

.date-picker-wrapper {
  width: 100% !important;
  height: 56px;


  flex-wrap: nowrap;

  * {
    outline: none !important;
  }

  .calendar-icon {
    position: absolute;

    width: 30px;
    height: 30px;

    bottom: 13px;
    right: 15px;

    path {
      fill: var(--base-03);

      transition: fill 150ms linear;
    }
  }

  &:hover {
    .calendar-icon > path {
      fill: var(--primary);
    }
  }

  .react-datepicker-wrapper {
    height: 56px;
    width: 100%;

    .react-datepicker__input-container {
      height: 100%;

      .date-picker {
        height: 100%;
        width: 100%;
      }
    }
  }

  .react-datepicker__tab-loop {
    .react-datepicker {
      @include shadow-24dp();

      border: none;
      border-radius: 8px;

      background-color: white;

      .react-datepicker__header {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        border: none;
        background-color: white;

        .react-datepicker__current-month {
          margin: 4px 12px 1em 12px;

          padding-bottom: 1em;

          border-bottom: 1px solid var(--base-04);
        }

        .react-datepicker__day-names {
          font-weight: normal;
          .react-datepicker__day-name {
            margin: 0px 0.5em;
          }
        }
      }

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__navigation {
        margin-top: 0.5em;

        &.react-datepicker__navigation--previous {
          border-right-color: var(--base-04);
        }
        &.react-datepicker__navigation--next {
          border-left-color: var(--base-04);
        }

        &:hover {
          &.react-datepicker__navigation--previous {
            border-right-color: var(--primary);
          }
          &.react-datepicker__navigation--next {
            border-left-color: var(--primary);
          }
        }
      }

      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day {
            color: var(--base-03);
            font-weight: normal;
            margin: 0.5em 0.5em;

            transition: background-color 150ms linear;

            &.react-datepicker__day--keyboard-selected {
              border-radius: 50%;

              color: white;

              background-color: var(--primary);
            }

            &:hover:not(.react-datepicker__day--keyboard-selected) {
              border-radius: 50%;
              background-color: rgba(var(--primary-raw), 0.3);
            }
          }
        }
      }

      .react-datepicker__year {
        .react-datepicker__header {
          height: 40px;
          margin-top: 7px;
        }

        .react-datepicker__year-container {

          .react-datepicker__year-container-text {
            margin: 0.5em 0px;

            transition: color 200ms linear;
            &:hover {
              color: var(--primary);
            }
          }
        }
      }
    }
  }

  .tooltip {
    .key-bindings-title {
      color: white;
      margin-bottom: 0.5em;
    }

    .key-bindings-container {
      width: 100%;
      align-items: flex-start;
    }
  }
}
