
$container-width: 30px;
$container-height: 20px;

$stick-width: 51%;
$stick-height: 2px;

.menu-button {

  width: $container-width;  // 60
  height: $container-height;  // 45 
  position: relative;
  
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;

  z-index: 102;

  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: $stick-height;  // 9
    width: $stick-width;  // 50%
    background: var(--base-dark);
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 300ms ease-in-out;
    -moz-transition: 300ms ease-in-out;
    -o-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;

    &:nth-child(even) {
      left: 50%;
      border-radius: 0 9px 9px 0;
    }

    &:nth-child(odd) {
      left: 0px;
      border-radius: 9px 0 0 9px;
    }

    &:nth-child(1),
    &:nth-child(2) {
      top: 0px;
    }

    &:nth-child(3),
    &:nth-child(4) {
      top: 8px;  // 18px
    }

    &:nth-child(5),
    &:nth-child(6) {
      top: 16px;  // 36px 
    }
  }

  &.open span {
    background: var(--base-dark);

    &:nth-child(1),
    &:nth-child(6) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:nth-child(2),
    &:nth-child(5) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    &:nth-child(1) {
      left: 2px;
      top: 3px;
    }

    &:nth-child(2) {
      left: calc(50% - 2px);
      top: 3px;
    }

    &:nth-child(3) {
      left: -50%;
      opacity: 0;
    }

    &:nth-child(4) {
      left: 100%;
      opacity: 0;
    }

    &:nth-child(5) {
      left: 2px;
      top: 14px;
    }

    &:nth-child(6) {
      left: calc(50% - 2px);
      top: 14px;
    }
  }
  &:active, &:focus {
    outline: none !important;
  }

  &.show {
    transform: translateY(0px);
    transition: transform 200ms ease-in-out;
  }

  &.hide {
    transform: translateY(-100px);
    transition: transform 200ms ease-in-out;
  }
}
