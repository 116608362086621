
.progress[value] {
  appearance: none;

  height: 4px;
  width: 100%;

  &::-webkit-progress-bar {
    background-color: rgba(197, 200, 203, 0.6);
  }

  &::-webkit-progress-value {
    background-color: var(--primary);
  }
}