$item-height: 80px;
.field-list {
  width: 100%;
  color: var(--primary);

  align-items: flex-start;

  .field-list-title {
    width: 100%;
    height: 40px;

    justify-content: flex-start;
    align-items: flex-end;

    box-sizing: border-box;
    padding: 1em 0px;

    color: var(--secondary-accent);
    font-size: 12px;
    text-transform: uppercase;

    border-bottom: 1px solid var(--secondary);
  }

  .field-list-content {
    width: 100%;

    .field {
      width: 100%;
      height: $item-height;

      justify-content: space-between;

      box-sizing: border-box;
      border-bottom: 1px solid var(--secondary);

      .field-name {
        align-items: flex-start;
        .field-hint {
          font-size: 12px;
          color: var(--secondary-accent);
        }
      }

      .field-value-container {
        justify-content: flex-end;

        .field-value {
          padding-right: 2em;
        }

        .field-edit {
          min-width: 60px;
          .field-icon {
            cursor: pointer;

            transition: color 200ms linear;

            &:hover {
              color: var(--secondary-accent);
            }
          }
        }
      }
    }
  }
}
