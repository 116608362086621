@import 'styles/partials/breakpoints';

.stepper {

  @mixin responsive {
    position: fixed;

    bottom: 0px;
    left: 0px;

    height: 72px;

    flex-direction: row;
    justify-content: space-evenly;

    .step {
      width: 40px;
      height: 40px;

      padding: 0px;

      .step-index {
        margin-right: 0px;

        font-weight: bold;
      }

      .step-name {
        display: none;
      }

    }
  }

  flex-grow: 1;
  width: 100%;

  align-items: flex-start;

  background-color: white;

  .step {
    flex-wrap: nowrap;

    margin: 1em 0px;
    padding: 0px 1em;
    justify-content: flex-start;
    font-weight: normal;
    font-size: 16px;

    color: var(--base-03);

    .step-index {
      border-radius: 50%;
      margin-right: 1em;
      box-sizing: border-box;
      height: 40px;
      max-height: 40px;
      min-height: 40px;
      width: 40px;
      max-width: 40px;
      min-width: 40px;
      font-size: 16px;
      color: var(--base-03);
      background-color: var(--base-light);
    }

    &.selected {
      color: var(--base-dark);
      .step-index {
        color: var(--base-light);
        background-color: var(--primary);
      }
    }

    &.completed {
      color: var(--base-02);
      background-color: transparent;
      cursor: pointer;
    }

    .complete-icon {
      color: var(--primary);
      width: 40px;
      height: 20px;

      margin-right: 1em;
      padding: 0px 6px;
    }
  }

  &.mobile {
    @include responsive();
  }

  @include respond-to(small) {
    @include responsive();
  }
}
