.textarea {

  outline: none !important;

  border: none;
  border: 1px solid var(--base-04);
  border-radius: 4px;

  box-sizing: border-box;

  font-size: 1rem;

  padding: 4px;
  background-color: white;

  color: var(--base-dark);

  padding: 1em;

  opacity: 0.5;

  transition: color 200ms linear, background-color 200ms linear,
    border-color 300ms linear;

  &:hover {
    
  }

  &:focus {
    opacity: 1;
    border-width: 2px;
    border-color: var(--primary);
  }

  &.no-left-border {
    border-radius: 0px 4px 0px 0px;
  }

  &.no-right-border {
    border-radius: 4px 0px 0px 0px;
  }
}
