@import 'styles/partials/ripple', 'styles/partials/breakpoints',
  'styles/partials/shadows';

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--primary);
  color: var(--primary-accent);

  box-sizing: border-box;

  height: 56px;

  border: 2px solid var(--primary);
  border-radius: 4px;

  padding: 0px 28px;

  cursor: pointer;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: white;

  @include primary-ripple();
  @include shadow-8dp();

  outline: none !important;

  transition: box-shadow 150ms ease-in-out, 150ms ease-in-out, color 150ms ease-in-out,
    border-color 150ms linear;

  svg {
    width: 25px;
    height: 25px;

    margin-left: 0.5em;

    path {
      fill: white;
      transition: fill 150ms linear;
    }
  }

  &:hover {
    background-color: var(--base);
    color: var(--primary);
    box-shadow: none;


    svg {
      path {
        fill: var(--primary);
      }
    }
  }

  &:active {
    box-shadow: none;
  }

  &:disabled, &.disabled-by-tier {
    background-color: var(--base-04);
    border-color: var(--base-04);
    color: var(--base-light);
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      transform: none;
    }
  }

  &.secondary {
    background-color: transparent;
    color: var(--base-dark);
    border-color: var(--base-04);

    box-shadow: none;

    .svg-inline--fa {
      color: var(--base-dark);
    }

    &:disabled, &.disabled-by-tier {
      opacity: 0.5;
      &:hover {
        transform: none;
      }
    }

    &:hover {
      color: var(--primary);
      border-color: var(--primary);
      .svg-inline--fa {
        color: var(--primary);
      }
    }

    &.disabled {
      color: var(--base-04);
      background-color: transparent;
      .svg-inline--fa {
        color: var(--base-04);
      }
    }
  }

  &.danger {
    background-color: var(--error-light);
    color: var(--error);
    border-color: var(--error-light);

    .svg-inline--fa {
      color: var(--error);
    }

    &:hover {
      color: white;
      border-color: var(--error);
      background-color: var(--error);
      .svg-inline--fa {
        color: white;
      }
    }
  }

  &.small {
    height: 40px;
  }

  &.borderless {
    border: none;
    background-color: transparent;
  }

  .button-loading-indicator {
    position: relative;
    right: -1em;
    animation: spin 1s linear 0s infinite;
  }
}

.button-w-icon {
  .button-w-icon__icon {
    position: relative;
    width: 16px;
    height: 16px;
    margin-left: 1em;
    z-index: 10;
    color: white;
  }
  &:disabled {
    .button-w-icon__icon {
      opacity: 0.5;
      color: #fffcea9c;
      pointer-events: none;
    }
  }

  &:hover {
    .button-w-icon__icon {
      color: var(--primary);
      path,
      circle {
        fill: var(--primary);
      }
    }
  }
}

.back-button {
  box-shadow: none;

  .back-button-icon {
    margin-right: 0.25em;
    margin-bottom: 0.1em;

    height: 25px;
    width: 25px;

    transform: rotate(180deg);

    path,
    circle {
      fill: var(--base-dark);
      transition: fill 150ms linear;
    }
  }
  &:hover {
    .back-button-icon {
      path,
      circle {
        fill: var(--primary);
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
