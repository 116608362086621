.input {
  height: 56px;

  max-height: 56px;

  outline: none !important;

  border: 1px solid var(--base-04);

  border-radius: 4px;

  box-sizing: border-box;

  font-size: 1rem;

  transition: color 200ms linear, background-color 200ms linear;

  background-color: white;

  padding: 0px 1em;

  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: normal;

  color: var(--base-dark);

  &:focus {
    opacity: 1;
    border-color: var(--primary);
  }
}

.input-w-button {
  flex-direction: row;

  width: 100%;

  .input {
    width: 100%;
  }

  .icon-wrapper {
    position: relative;

    width: 30px;
    height: 30px;
    
    bottom: 42px;
    left: calc(100% - 42px);

    border-radius: 50%;

    z-index: 10;

    cursor: pointer;

    background-color: var(--primary);

    transition: background-color 150ms linear;

    svg {
      width: 20px;
      height: 20px;
      path {
        fill: white;
        transition: fill 150ms linear;
      }
    }

    &:hover {
      .icon-wrapper {
        background-color: white;

        svg>path {
          fill: var(--primary);
        }
      }
    }
  }
}

.input-w-error {
  display: flex;
  flex-direction: column;

  width: 100%;

  .input {
    width: 100%;
  }

  &.has-error > .input {
    border-color: var(--error);
    color: var(--error);
  }

  .error {
    height: 0px;
    font-size: 0.8em;
    font-weight: 200;
    color: var(--error);

    .error-icon {
      margin-right: 0.5em;
    }
  }

  .input-error-transition-enter {
    transform: translatex(-100px);
    opacity: 0;
  }
  .input-error-transition-enter-active {
    transform: translateY(0px);
    opacity: 1;
    transition: transform 200ms ease-in-out, opacity 200ms linear;
  }
  .input-error-transition-exit {
    transform: translateX(0px);
    opacity: 1;
  }
  .input-error-transition-exit-active {
    transform: translateX(100px);
    opacity: 0;
    transition: transform 200ms ease-in-out, opacity 200ms linear;
  }
}

.password-input-container {
  height: 56px;

  .input {
    width: 100%;
  }
  .icon {
    position: relative;

    top: -46.5px;
    left: calc(100% - 50px);

    width: 36px;
    height: 36px;

    cursor: pointer;
    path {
      fill: var(--base-04);
    }

    &:hover {
      path {
        fill: var(--base-dark);
      }
    }
  }
}

.checkbox {
  appearance: none;

  box-sizing: border-box;

  width: 16px;
  height: 16px;

  border-radius: 3px;
  border: 1px solid var(--base-04);

  background-color: transparent;

  cursor: pointer;

  margin: 0px 0.5em 0px 0px;

  pointer-events: all;

  outline: none !important;

  transition: background-color 200ms linear;

  &:checked {
    background-color: var(--primary);
    border-color: var(--primary);

    &::after {
      content: '\2714';
      font-size: 16px;
      position: relative;
      top: -2px;
      left: 0px;
      color: white;
    }
  }

  &:hover {
    border-color: var(--primary);
  }
}

.radio {
  appearance: none;

  box-sizing: border-box;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border: 2px solid var(--base-04);
  border-radius: 50%;

  margin: 0px 0.5em 0px 0.5em;

  background-color: white;

  outline: none !important;

  transition: border-color 150ms linear;

  &:hover {
    border-color: var(--primary);
  }

  &:checked {
    border-color: var(--primary);

    &::after {
      content: '';
      background-color: var(--primary);
      border: 1px solid transparent;
      box-sizing: border-box;
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }
  }
}

::-webkit-input-placeholder {
  color: var(--base-04);
  opacity: 1;
}
:-ms-input-placeholder {
  color: var(--base-04);
  opacity: 1;
}
::-moz-placeholder {
  color: var(--base-04);
  opacity: 1;
}
::placeholder {
  color: var(--base-04);
  opacity: 1;
}
