@import 'styles/partials/breakpoints';

@mixin responsive {
  align-items: flex-end;

  .modal {
    height: 90vh;

    border-radius: 16px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    border: none;

    .modal-body {
      padding: 0px;
    }
  }
}

.modal-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;

  top: 0px;
  left: 0px;

  background-color: #00000033;
  color: var(--base-dark);

  z-index: 1000;

  transition: opacity 200ms linear;
  .modal {
    width: auto;
    height: auto;

    justify-content: flex-start;
    flex-wrap: nowrap;

    padding: 1em;
    box-sizing: border-box;

    border: 1px solid var(--base-04);
    border-radius: 8px;
    background-color: white;

    transition: transform 200ms ease-in-out, opacity 200ms linear;

    .modal-close-icon {
      position: absolute;

      right: 1em;
      top: 1em;

      width: 30px;
      height: 30px;

      z-index: 10;

      cursor: pointer;
      &:hover {
        color: var(--base-03);
      }
    }

    .modal-body {
      width: 100%;
      height: 100%;

      padding: 0px calc(80px - 16px);

      .modal-title {
        text-align: center;

        margin-top: 0px;
      }

      .modal-message {
        text-align: center;

        color: var(--base-03);
      }

      .confirmation-modal-actions {
        margin-top: 48px;
      }

      .loading-button {
        margin-left: 10px;
      }
    }
  }

  &.confirmation-modal {
    .modal {
      width: 540px;
      height: 370px;

      .confirmation-modal-actions {
        width: 100%;

        justify-content: space-evenly;
        .button {
          width: 40%;
        }
      }
    }
  }

  @include respond-to(small) {
    @include responsive();
  }

  &.mobile {
    @include responsive();
  }
}

.modal-transition-enter {
  &.modal-backdrop {
    opacity: 0;
    .modal {
      opacity: 0;
      transform: translateY(50px);
    }
    &.mobile {
      .modal {
        transform: translateY(100%);
      }
    }
  }
}
.modal-transition-enter-active {
  &.modal-backdrop {
    opacity: 1;
    .modal {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
.modal-transition-exit {
  &.modal-backdrop {
    opacity: 1;
    .modal {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
.modal-transition-exit-active {
  &.modal-backdrop {
    opacity: 0;
    .modal {
      opacity: 0;
      transform: translateY(-50px);
    }
    &.mobile {
      .modal {
        transform: translateY(100%);
      }
    }
  }
}
