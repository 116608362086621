.heading {
  &.lvl-1 {
    font-family: Averta;
    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--base-dark);
  }
  &.lvl-2 {
    font-family: Averta;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: var(--base-dark);
  }
  &.lvl-3 {
    font-family: Averta;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
    color: var(--base-dark);
  }
  &.lvl-4 {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.75px;
    text-align: center;
    color: var(--base-dark);
  }
  &.lvl-5 {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    line-height: 28px;
    letter-spacing: 0.5px;
    color: var(--base-dark);
  }
  &.lvl-6 {
  }
}
