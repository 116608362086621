.select {
  .climadjust-select__control {
    border-radius: 4px;
    font-weight: normal;
    border-width: 1px;
    height: 56px;

    .climadjust-select__value-container {
      padding-left: 18px;
      .climadjust-select__single-value {
      }
    }

    .climadjust-select__indicators {
      transition: transform 150ms linear;
      .climadjust-select__indicator-separator {
        display: none;
      }
    }
    &.climadjust-select__control--is-focused {
      border-color: var(--primary);
      box-shadow: none;
    }

    &.climadjust-select__control--menu-is-open {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-color: transparent;

      .climadjust-select__indicators {
        transform: rotate(180deg);
      }
    }
  }

  .climadjust-select__menu {
    height: auto;

    top: calc(100% - 2px);

    margin-top: 0px;

    border: 1px solid var(--primary);
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    box-sizing: border-box;

    box-shadow: none;

    z-index: 1000;

    .climadjust-select__menu-list {
      box-sizing: border-box;
      padding: 4px 8px;
      .climadjust-select__option {
        display: flex;
        align-items: center;

        min-height: 38px;
        border-radius: 4px;

        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: start;
        color: var(--base-03);

        transition: color 150ms linear;

        &.climadjust-select__option--is-focused {
          color: var(--primary);
          background-color: transparent;
        }

        &.climadjust-select__option--is-selected {
          background-color: var(--primary);
          color: var(--base-light);
        }
      }
    }
  }
}

.mobile-select-wrapper {
  width: 100%;
  .select-mobile {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    
    width: 100%;
    height: 56px;
    
    padding-left: 1em;
    
    border-radius: 4px;
    border-width: 1px;
    background-color: white;
    border: 1px solid var(--base-04);
    
    font-weight: normal;
    font-size: 16px;
  }

  .chevron {
    position: absolute;

    right: 15px;
    margin-top: 15px;
    
    width: 25px;
    height: 25px;

    transform: rotate(90deg);

    path {
      fill: var(--base-dark);
    }
  }
}
  