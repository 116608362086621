.star-rating {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .star-wrapper {
    padding: 0px 0.25em;

    cursor: pointer;

    svg {
      width: 30px;
      height: 30px;
      
      fill: var(--base-04);

      transition: fill 150ms linear;

      &.checked {
        fill: var(--primary);
      }
    }
  }
}