
.gradients-container {
  position: absolute;

  width: 100%;
  height: 50vh;

  bottom: 0px;
  left: 0px;

  overflow: visible;

  z-index: 0;

  .blur {
    position: absolute;

    width: 100%;
    height: 100%;

    background-color: transparent;

    backdrop-filter: blur(5px);
  }

  .gradient{
    width: 100%;
    height: 100%;

    z-index: 1;
  }
}