.stepper-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;

  flex-wrap: nowrap;

  background-color: var(--base);

  z-index: 990;

  .stepper-container {
    background-color: white;

    min-width: 300px;
    max-width: 300px;
    height: 100%;
    box-sizing: border-box;
    padding: 1em;

    justify-content: flex-start;
    
    z-index: 10;
  }

  .content {
    flex-grow: 1;
    height: 100%;
    justify-content: flex-start;
    box-sizing: border-box;

    flex-wrap: nowrap;

    overflow-y: hidden;
    background-color: var(--base-light);

    .stepper-modal-header {
      justify-content: space-between;
      
      width: 100%;
      height: calc(40px + 2em);
      
      padding-right: 2em;

      .close-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;

        path {
          transition: fill 150ms linear;
        }

        &:hover {
          path {
            fill: var(--primary);
          }
        }
      }

    }
    .scroll {
      overflow-y: auto;
      height: calc(100vh - 40px - 2em);
      align-items: flex-start;
      width: 100%;
      .container {
        width: 100%;
        margin-bottom: 2em;
      }
    }
  }
}
