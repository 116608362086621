.file-drop {
  border: 2px dashed var(--primary);
  background-color: var(--base-light);

  box-sizing: border-box;

  transition: background-color 200ms linear;

  border-radius: 8px;

  &:hover {
    background-color: white;
  }

  .hidden-input {
    position: absolute;
    
    width: 100%;
    height: 100%;

    top: 0px;
    left: 0px;

    opacity: 0;
  }
}
