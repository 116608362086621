.grid-container {

  width: 100%;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 1em;

  justify-content: center;	
  align-content: flex-start;
  
  &.main {
    grid-template-columns: repeat(12, 76px);
    grid-column-gap: 24px;
  }
}

.grid-item {
  justify-self: stretch;
}

.flex-row {
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  position: relative;
  &.justify-start {
    justify-content: flex-start;
  }
}

.flex-col {
  display: flex;
  flex-direction: column;

  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  position: relative;
}

.fb-25 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 25%;
  min-width: 25%;
  max-width: 100%;
}

.fb-33 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 33.33%;
  max-width: 33.33%;
  max-width: 100%;
}

.fb-50 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  max-width: 50%;
  max-width: 100%;
}

.fb-66 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 66%;
  max-width: 66%;
  max-width: 100%;
}
.fb-75 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 75%;
  max-width: 75%;
  max-width: 100%;
}

.fb-100 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  min-width: 100%;
  max-width: 100%;
}
