.avatar-image-wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--primary);
  
  transition: background-color 200ms linear;
  overflow:hidden;

  img {
    max-width:100%;
    max-height:100%;
  }

  .avatar-icon {
    width: 42px;
    height: 42px;

    transition: fill 200ms linear;

    path {
      fill: white;
    }
  }

  // &:hover {
  //   background-color: var(--base-light);
  //   svg {
  //     path {
  //       fill: var(--primary);
  //     }
  //   }
  // }
}
